body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

main {
	margin-top: 1em;
}

.help-button {
	width: 25px;
	height: 25px;
	padding: 0px 6px;
	border-radius: 15px;
	/* text-align: center;  */
}

.prose {
	max-width: 800px;
	margin: 0 auto;
	padding: 1em;
	font-family: sans-serif;
}

h1 {
	font-family: sans-serif;
	font-size: 2rem;
	font-weight: normal;
	letter-spacing: 0.02em;
	color: #555;
	margin-bottom: 0.75rem;
}

h2 {
	font-family: sans-serif;
	font-size: 1.5rem;
	font-weight: normal;
	letter-spacing: 0.02em;
	color: #555;
	margin-bottom: 0.5rem;
}

h3 {
	font-family: sans-serif;
	font-size: 1.25rem;
	font-weight: bold;
	letter-spacing: 0.02em;
	color: #555;
	margin-bottom: 0.5rem;
}

h4 {
	font-family: sans-serif;
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: 0.02em;
	color: #555;
	margin-bottom: 0.5rem;
}

.badge-container {
	/* Just wrap the image */
	padding: 0px;
	/* Don't show a border */
	border-width: 0px;
	/* Transparent background */
	background-color: transparent;
}

.badge-container:hover {
	/* Make the badge a bit bigger */
	transform: scale(1.1);
}
