$primary: #ed8d38;
$primary-hover: #d37c30;

.primary-navbar {
	background-color: #fff !important;
}

.logo-text {
	// Fira Code
	font-family: "Fira Code", monospace;
}

.primary-button {
	background-color: $primary !important;
	border-color: $primary !important;
	// hover
	&:hover {
		background-color: $primary-hover !important;
		border-color: $primary-hover !important;
	}
}

// Progres bar
.primary-progress-bar .progress-bar-animated {
	background-color: $primary !important;
}

// Rounded corners, scrollable, don't touch the the edge of large screens
.primary-card {
	border-radius: 10px;
	overflow: auto;
	margin: 10px;
}

/* Circle with initials in it */
.logo-initials {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: $primary;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-wrapper {
	background-color: #ed8d38;
	padding-top: 1px;
	padding-bottom: 1px;
}

.page-content {
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	margin: 30px auto;
	border-radius: 40px;
	overflow: hidden;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

@media (max-width: 768px) {
	.page-content {
		margin: 10px 0px;
	}
}

.name-title-location {
	// align center vertically on larger screens
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.headshot {
	width: 200px;
	height: 200px;
	object-fit: cover;
	border-radius: 50%;
	// on smaller screens, add margin below the image
	@media (max-width: 768px) {
		margin-bottom: 20px;
	}
}

// Data Science, GenAI, Web Development, Music, JavaScript, Python, React, AWS

.badge-datascience {
	background-color: #e08e4b !important;
}
.badge-genai {
	background-color: #dfb186 !important;
}
.badge-webdevelopment {
	background-color: #f0b887 !important;
}
.badge-music {
	background-color: #f0d787 !important;
}
.badge-javascript {
	background-color: #f0b887 !important;
}
.badge-python {
	background-color: #f09c87 !important;
}
.badge-react {
	background-color: #f09c87 !important;
}
.badge-aws {
	background-color: #f0d087 !important;
}
